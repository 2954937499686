import axios from "../utils/axiosCustomize";
import axiosToken from "../utils/axiosToken";

export const postCreateNewUser = (fullName, email, generation, phoneNumber, password, facebook, role) => {
    return axios.post('api/v1/users', {
        fullName, email, generation, phoneNumber, password, facebook, role
    })
}

export const getUserWithPaginate = (page, limit, sort, isCheckin, search) => {
    return axios.get(`api/v1/users?page=${page}&limit=${limit}&sort=${sort}&isCheckin=${isCheckin}&q=${search}`)
}

export const getUserWithPaginateAll = (page, limit, sort, search) => {
    return axios.get(`api/v1/users?page=${page}&limit=${limit}&sort=${sort}&q=${search}`)
}


export const updateUser = (id, email, phoneNumber, fullName, generation, image, isCheckin) => {
    const data = new FormData();
    data.append('email', email);
    data.append('phoneNumber', phoneNumber);
    data.append('fullName', fullName);
    data.append('generation', generation);
    data.append('image', image);
    data.append('isCheckin', isCheckin);
    return axios.patch(`api/v1/users/${id}`, data);
}

export const loginUser = (account, password, recaptchaToken) => {
    return axios.post('api/v1/auth/login', { account, password },
        {
            headers: {
                recaptcha: recaptchaToken
            }
        }
    );
}

export const postRegister = (fullName, email, generation, phoneNumber, image, facebook, recaptchaToken) => {
    const data = new FormData();
    data.append('fullName', fullName);
    data.append('email', email);
    data.append('generation', generation);
    data.append('phoneNumber', phoneNumber);
    data.append('image', image);
    data.append('facebook', facebook);
    return axios.post('api/v1/users/signup', data, {
        headers: {
            recaptcha: recaptchaToken
        }
    })
}

export const checkinPhone = (q) => {
    return axios.patch(`api/v1/users/checkin?q=${q}`);
}

export const checkinQR = (id) => {
    return axios.patch(`api/v1/users/checkin/${id}`);
}

export const checkinFace = (image) => {
    const data = new FormData();
    data.append('image', image);
    return axios.post('api/v1/users/checkin-face', data);
}

export const exportExcel = () => {
    return axios.get('api/v1/users/export-excel', {
        'responseType': 'blob',
    });
}

export const getRenewQR = (q, recaptchaToken) => {
    return axios.get(`api/v1/users/renew-qr?q=${q}`,
        {
            headers: {
                recaptcha: recaptchaToken
            }
        }
    );
}

export const browserImageUser = (id) => {
    return axios.post(`api/v1/users/browse-image?q=${id}`);
}

export const generateLink = (id) => {
    return axios.get(`api/v1/users/generate-link?q=${id}`);
}

export const updateImageUser = (image, token) => {
    const data = new FormData();
    data.append('image', image);
    return axiosToken.patch('api/v1/users/update-image', data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const checkLink = (answer, token) => {
    return axiosToken.get(`api/v1/users/check-link?answer=${answer}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}