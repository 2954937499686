import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';

const TableUser = (props) => {
    const { search, fetchLink, fetchAllUserCheckWithPaginate, checkAll, uncheck, checked, LIMIT_USER, setPagePos, listUser, handleClickBrowser, handleClickView, handleClickUpdate, pageCount, fetchAllUserWithPaginate, pagePos } = props;
    const handlePageClick = (event) => {
        if (checkAll) {
            fetchAllUserWithPaginate(+event.selected + 1, search)
            setPagePos(+event.selected + 1)
        } else if (checked) {
            fetchAllUserCheckWithPaginate(+event.selected + 1, true, search)
            setPagePos(+event.selected + 1)
        } else if (uncheck) {
            fetchAllUserCheckWithPaginate(+event.selected + 1, false, search)
            setPagePos(+event.selected + 1)
        }
    };
    const totalPage = Math.ceil(pageCount / LIMIT_USER);
    return (
        <>
            <Table striped bordered hover size="xl" responsive >
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Họ và tên</th>
                        <th>Khóa</th>
                        <th>Email</th>
                        <th>Số điện thoại</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {listUser && listUser.length > 0 && listUser.map((item, index) => {
                        return (
                            <tr key={`table-user-${index}`}>
                                <td className="align-middle center">{pagePos * 10 - 10 + index + 1}</td>
                                <td className="align-middle center">{item.fullName}</td>
                                <td className="align-middle center">{item.generation}</td>
                                <td className="align-middle center">{item.email}</td>
                                <td className="align-middle center">{item.phoneNumber}</td>
                                <td>
                                    <div className='btn-table'>
                                        <button className='btn btn-secondary' onClick={() => handleClickView(item)}>Xem</button>
                                        <button className='btn btn-warning' onClick={() => handleClickUpdate(item)}>Cập nhật</button>
                                        {item.image !== null && item.isAccessImage === false && <button className='btn btn-danger' onClick={() => handleClickBrowser(item)}>Duyệt ảnh</button>}
                                        {item.image !== null && item.isAccessImage === false && <button className='btn btn-success' onClick={() => fetchLink(item.id)}>Tạo link</button>}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    {listUser && listUser.length === 0 &&
                        <tr>
                            <td colSpan={4}>Not Found Data</td>
                        </tr>}
                </tbody>
            </Table>
            <div className='paginate'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={totalPage}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={pagePos - 1}
                />
            </div>
        </>
    );
}

export default TableUser;