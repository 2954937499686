import React, { useState } from 'react';
import { MdOutlineCelebration } from "react-icons/md";
import { getRenewQR } from '../../services/apiService';
import { toast } from 'react-toastify';
import ModalRenew from './ModalRenew';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const RenewQR = () => {
    const [q, setQ] = useState("");
    const [idUser, setIdUser] = useState("");
    const [showRenewModal, setShowRenewModal] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [checkHome, setCheckHome] = useState(true);
    const navigate = useNavigate();
    const [verfied, setVerfied] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const handleRenewQR = async () => {
        if (q.length > 0) {
            let res = await getRenewQR(q, recaptchaToken);
            if (res && res.statusCode === 200) {
                toast.success('Cấp lại mã QR thành công');
                setIdUser(res.data.id)
                setShowRenewModal(true);
                setCheckHome(false);
            } else {
                toast.error('Không tìm thấy Email hoặc SĐT!');
            }
        } else {
            setIsCheck(true);
        }
    }
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setVerfied(true);
    };
    const handleChange = (e) => {
        setQ(e.target.value);
        setIsCheck(false)
    }
    useEffect(() => {
        document.title = "RenewQR - V.I.P English Club";
    }, [])
    return (
        <div className='register-container'>
            <div className='signup'>
                <div className='birthday-layout'>
                    <div className='n-grid'>
                        <div style={{ gridColumn: 'span 1/ span 1' }}>
                            <div className='layout-left'>
                                <div className='left-body'>
                                    <NavLink to='/'><img src='logo-vip.png' alt='logo' /></NavLink>
                                    <h1>Trang cấp lại mã QRCode</h1>
                                </div>
                                <img className='left-birthday' src='birthday.png' alt='birthday' />
                                <img className='left-cloud' src='cloud.png' alt='cloud' />
                            </div>
                        </div>
                        <div style={{ gridColumn: 'span 1/ span 1' }}>
                            <div className='layout-right'>
                                <div className='right-body'>
                                    <div className='qr-information'>
                                        <h1 style={{ fontWeight: '700', marginBottom: '20px' }}>Chào mừng anh/chị!</h1>
                                        <p>Anh/chị vui lòng điền chính xác thông tin bên dưới.</p>
                                        <p className='text-note' style={{ marginBottom: '50px' }}>Lưu ý: Website hoạt động tốt nhất trên trình duyệt chrome, edge cho chức năng tải xuống ảnh QR.</p>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="fullnameid" className="form-label mb-0">Nhập email hoặc số điện thoại</label><span className="n-form-item-label__asterisk" style={{ color: 'blue' }}>&nbsp;*</span>
                                        <input id="fullname" type="text" className="form-control" onChange={(event) => handleChange(event)} placeholder='Nhập email hoặc số điện thoại' aria-describedby="nameHelp" />
                                    </div>
                                    {isCheck && <div className='check-notify' style={{ fontSize: '16px', color: 'red', margin: '5px 0' }}>Trường này không được trống</div>}
                                    <div style={{ marginBottom: '30px' }} className='check-robot'>
                                        <ReCAPTCHA
                                            sitekey="6Lfjk2YnAAAAANFWLKO1MjHnKlvxJqShsnkRvF0R"
                                            onChange={handleRecaptchaChange}
                                        />
                                    </div>
                                    <div className='button-action'>
                                        {checkHome === true ?
                                            <button disabled={!verfied} className='btn btn-primary' onClick={handleRenewQR}><MdOutlineCelebration /> Cấp lại</button> :
                                            <button style={{ backgroundColor: '#0d6efd', color: '#fff' }} onClick={() => navigate('/')}>Trang chủ</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalRenew show={showRenewModal} setShow={setShowRenewModal} idUser={idUser} />
        </div>
    )
}

export default RenewQR
