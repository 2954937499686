import React, { useEffect, useState } from 'react'
import { FcPlus } from "react-icons/fc";
import ModalConfirm from './ModalConfirm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { checkLink, updateImageUser } from '../../../services/apiService';
import { ImSpinner2 } from 'react-icons/im';
import { toast } from 'react-toastify';

const UpdateImage = () => {
    const [previewImage, setPreviewImage] = useState("");
    const [image, setImage] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const location = useLocation();
    const [name, setName] = useState("");
    const [answerCheck, setAnswerCheck] = useState(true);
    const [successInfor, setSuccessInfor] = useState(true);
    const [linkToken, setLinkToken] = useState(() => {
        const params = queryString.parse(location.search);
        return params.q
    });
    const [isLoading, setIsLoading] = useState(true);
    const handleUploadImage = (event) => {
        if (event.target && event.target.files && event.target.files[0]) {
            setPreviewImage(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        }
    }
    useEffect(() => {
        document.title = "UpdateFace - V.I.P English Club";
        fetchUpdateImage(true);
    }, [])

    const pushUpdateImgae = async () => {
        let res = await updateImageUser(image, linkToken);
        if (res && res.statusCode === 200) {
            toast.success('Cập nhật ảnh thành công');
            setSuccessInfor(false);
        } else {
            toast.error(res.message);
        }
    }
    const fetchUpdateImage = async (answer) => {
        let res = await checkLink(answer, linkToken);
        if (res && res.statusCode === 200 && res.data !== null) {
            setName(res.data.fullName);
            setShowConfirm(true);
        } else {
            setAnswerCheck(false)
            setShowConfirm(false);
            setIsLoading(false);
            setName('');
        }
    }

    const handleCancel = () => {
        fetchUpdateImage(false);
        setShowConfirm(false);
        setIsLoading(false);
        setAnswerCheck(false)
    }
    const handleConfirm = async () => {
        setShowConfirm(false);
        setIsLoading(false);
    }
    return (
        <div className='register-container'>
            <div className='signup'>
                <div className='birthday-layout'>
                    <div className='n-grid'>
                        <div style={{ gridColumn: 'span 1/ span 1' }}>
                            <div className='layout-left'>
                                <div className='left-body'>
                                    <img src='logo-vip.png' alt='logo' />
                                    <h1>Trang cập nhật khuôn mặt</h1>
                                </div>
                                <img className='left-birthday' src='birthday.png' alt='birthday' />
                                <img className='left-cloud' src='cloud.png' alt='cloud' />
                            </div>
                        </div>
                        <div style={{ gridColumn: 'span 1/ span 1' }}>
                            <div className='layout-right'>
                                <div className='right-body'>
                                    <div className='qr-information'>
                                        <h1 style={{ fontWeight: '600', marginBottom: '30px', textAlign: 'center' }}>Chào mừng anh/chị!</h1>
                                        {isLoading && <div>
                                            <p><ImSpinner2 className='loading-spinner' /></p>
                                            <p style={{ marginBottom: '30px', textAlign: 'center' }} className='text-note'>Lưu ý: Đang kiểm tra liên kết vui lòng chờ.</p>
                                        </div>}
                                        {!successInfor && <p style={{ marginBottom: '30px', textAlign: 'center' }} className='text-note'>Cập nhật khuôn mặt thành công.</p>}
                                        {!answerCheck && <p style={{ marginBottom: '30px', textAlign: 'center' }} className='text-note'>Liên kết đã hết hạn. Vui lòng liên hệ với quản trị viên để cấp liên kết mới.</p>}
                                        {answerCheck === true && !isLoading && successInfor && <p style={{ marginBottom: '30px', textAlign: 'center' }} className='text-note'>Lưu ý: Liên kết này chỉ có thể sử dụng 1 lần và sẽ hết hạn sau 2 tiếng nếu không sử dụng.</p>}
                                    </div>
                                    {answerCheck === true && !isLoading && successInfor && <>
                                        <div>
                                            <label htmlFor='labelUpload' className="form-label label-upload"><FcPlus />Tải ảnh của bạn</label>
                                            <input id="labelUpload" type='file' hidden onChange={(event) => handleUploadImage(event)} />
                                        </div>
                                        <div className='img-preview' style={{ height: "150px" }}>
                                            {previewImage ?
                                                <img src={previewImage} />
                                                :
                                                <span>Preview Image</span>
                                            }
                                        </div>
                                        <button className='btn btn-primary' style={{ margin: '20px auto', display: 'block', width: '100%', fontWeight: '600', fontSize: '18px' }} onClick={pushUpdateImgae}>Cập nhật</button>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirm name={name} show={showConfirm} setShow={setShowConfirm} handleConfirm={handleConfirm} handleCancel={handleCancel} />
        </div>
    )
}

export default UpdateImage