import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginUser } from '../../services/apiService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { doLogin } from '../../redux/action/userAction';
import { ImSpinner2 } from 'react-icons/im';
import { useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [verfied, setVerfied] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setVerfied(true);
    };
    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const handleClickLogin = async () => {
        setIsLoading(true)
        if (!email || !password) {
            toast.error('Bạn thiếu thông tin rồi :((');
            setIsLoading(false);
            return;
        }
        if (!re.test(email)) {
            toast.error('Email không chính xác!');
            setIsLoading(false);
            return;
        }
        let res = await loginUser(email, password, recaptchaToken)
        if (res && res.statusCode === 200) {
            dispatch(doLogin(res.data));
            toast.success('Đăng nhập thành công');
            navigate('/admin')
        } else {
            toast.error('Tài khoản không tồn tại');
            setIsLoading(false)
        }
    }
    const handleKeyDown = (event) => {
        if (event && event.key === 'Enter') {
            handleClickLogin();
        }
    }
    useEffect(() => {
        document.title = "Login - V.I.P English Club";
    }, [])
    return (
        <>
            <div className='login-container'>
                <div className='login-main'>
                    <div className='login-content'>
                        <div className='login-title'>
                            Vipform
                        </div>
                        <div className='login-sub'>Xin chào, đây là ai?</div>
                        <div className='login-input'>
                            <label>Email</label>
                            <input type={"email"} placeholder='forcewech@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='login-input' style={{ marginBottom: '20px' }}>
                            <label>Mật khẩu</label>
                            <input type={"password"} placeholder='Enter your password' value={password} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div style={{ marginBottom: '30px' }} className='check-robot'>
                            <ReCAPTCHA
                                sitekey="6Lfjk2YnAAAAANFWLKO1MjHnKlvxJqShsnkRvF0R"
                                onChange={handleRecaptchaChange}
                            />
                        </div>
                        <div>
                            <button disabled={isLoading} className='login-submit' onClick={handleClickLogin}>
                                {isLoading && <ImSpinner2 className='loading-spinner' />}
                                Đăng nhập
                            </button>
                        </div>
                        <a className='go-home' href='/'>&#60;&#60; Quay về trang chủ</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login