import React, { useEffect, useRef, useState } from 'react';
import { checkinFace } from '../../../services/apiService';
import ModalCheckin from './ModalCheckin';

const CheckinFace = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [count, setCount] = useState(5);
    const [modal, setModal] = useState("hide");
    const streaming = useRef(false);
    const [nameUser, setNameUser] = useState("");
    const [intervalId, setIntervalId] = useState(null);
    const [generationUser, setGenerationUser] = useState("");

    const incrementCount = () => {
        setCount(count - 1);
    };

    useEffect(() => {
        const timer = setTimeout(incrementCount, 1000); // Tăng giá trị đếm sau mỗi 1000ms (1 giây)
        if (count === 0) {
            streaming.current = true;
            setModal("hide");
            clearTimeout(timer);
        }
        return () => {
            // Hủy bỏ đếm khi component unmount
            clearTimeout(timer);
        };
    }, [count]);
    // Start camera
    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            streaming.current = true;
            setIntervalId(setInterval(captureAndSend, 500));
        } catch (error) {
            console.error('Lỗi khi truy cập camera:', error);
        }
    };

    // Helper function to convert Data URI to Blob
    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }
    const captureAndSend = () => {
        if (streaming.current) {
            canvasRef.current.width = videoRef.current.videoWidth;
            canvasRef.current.height = videoRef.current.videoHeight;
            const ctx = canvasRef.current.getContext('2d');
            ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
            const imageData = dataURItoBlob(canvasRef.current.toDataURL('image/jpeg'));
            sendImageToServer(imageData);
        }
    };
    // Send image to the server via an API
    const sendImageToServer = async (imageData) => {

        let res = await checkinFace(imageData);
        if (res && res.statusCode === 200) {
            setNameUser(res.data.fullName);
            setGenerationUser(res.data.generation);
            setModal('open')
            setCount(5);
            streaming.current = false;
            return;
        }
    };

    useEffect(() => {
        return () => {
            // Cleanup when the component unmounts
            clearInterval(intervalId);
        };
    }, [intervalId]);
    useEffect(() => {
        startCamera();
        document.title = "Check-inFace - V.I.P English Club";
    }, [])
    return (
        <div className='check-face-container'>
            <div className='title'>FACE CHECK-IN</div>
            <div id="outer">
                <video className='video' ref={videoRef} autoPlay={true}></video>
            </div>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            <ModalCheckin generationUser={generationUser} setModal={setModal} nameUser={nameUser} modal={modal} count={count} />
        </div>
    )
}

export default CheckinFace