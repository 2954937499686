import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';

const TableUserCheck = (props) => {
    const { LIMIT_USER, search, setPagePos, listUser, handleClickView, pageCount, fetchCheckedUserWithPaginate, pagePos } = props;
    const handlePageClick = (event) => {
        fetchCheckedUserWithPaginate(+event.selected + 1, search)
        setPagePos(+event.selected + 1)
    };
    const totalPage = Math.ceil(pageCount / LIMIT_USER);
    return (
        <>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Họ và tên</th>
                        <th>Khóa</th>
                        <th>Email</th>
                        <th>Số điện thoại</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {listUser && listUser.length > 0 && listUser.map((item, index) => {
                        return (
                            <tr key={`table-user-${index}`} >
                                <td className="align-middle center">{pagePos * 10 - 10 + index + 1}</td>
                                <td className="align-middle center">{item.fullName}</td>
                                <td className="align-middle center">{item.generation}</td>
                                <td className="align-middle center">{item.email}</td>
                                <td className="align-middle center">{item.phoneNumber}</td>
                                <td>
                                    <button style={{ width: '100%' }} className='btn btn-secondary' onClick={() => handleClickView(item)}>Xem</button>
                                </td>
                            </tr>
                        )
                    })}
                    {listUser && listUser.length === 0 &&
                        <tr>
                            <td colSpan={4}>Not Found Data</td>
                        </tr>}
                </tbody>
            </Table>
            <div className='paginate'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={totalPage}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        </>
    );
}

export default TableUserCheck;