import { useEffect, useState } from "react";
import { AiOutlineReload, AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { FcPlus } from "react-icons/fc";
import { exportExcel, generateLink, getUserWithPaginate, getUserWithPaginateAll } from '../../../services/apiService';
import ModalCreateUser from "./ModalCreateUser";
import ModalUpdateUser from "./ModalUpdateUser";
import ModalViewUser from "./ModalViewUser";
import TableUser from "./TableUser";
import BrowserImage from "./BrowserImage";
import { toast } from "react-toastify";
import { useRef } from "react";

const ManageUser = () => {
    const LIMIT_USER = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pagePos, setPagePos] = useState(1);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const token = useRef("");
    const [listUpdateUser, setListUpdateUser] = useState({});
    const [listUser, setListUser] = useState([]);
    const [initalList, setInitalList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [checked, setChecked] = useState(false);
    const [uncheck, setUnCheck] = useState(false);
    const [search, setSearch] = useState("");
    const [showBrowserModal, setShowBrowserModal] = useState(false);

    //filter action
    const [sort, setSort] = useState("createdAt:desc");

    const fetchAllUserWithPaginate = async (page, search) => {
        let res = await getUserWithPaginateAll(page, LIMIT_USER, sort, search);
        if (res.statusCode === 200) {
            setListUser(res.data.users);
            setInitalList(res.data.users);
            setPageCount(res.data.count);
        }
    }
    const fetchAllUserCheckWithPaginate = async (page, isCheckin, search) => {
        let res = await getUserWithPaginate(page, LIMIT_USER, sort, isCheckin, search);
        if (res.statusCode === 200) {
            setListUser(res.data.users);
            setInitalList(res.data.users);
            setPageCount(res.data.count);
        }
    }
    useEffect(() => {
        setPagePos(1)
        fetchAllUserWithPaginate(1, search)
        setCheckAll(true);
        document.title = "Admin - V.I.P English Club";
    }, [])
    useEffect(() => {
        if (checkAll) {
            setPagePos(1)
            fetchAllUserWithPaginate(1, search)
        } else if (checked) {
            setPagePos(1)
            fetchAllUserCheckWithPaginate(1, true, search)
        } else if (uncheck) {
            setPagePos(1)
            fetchAllUserCheckWithPaginate(1, false, search)
        }
    }, [sort])
    const copyInfor = async (value) => {
        const copyText = value;
        try {
            await navigator.clipboard.writeText(copyText)
            toast.success('Copy thành công!')
        }
        catch (error) {
            toast.error('Copy thất bại!')
        }
    }
    const handleClickUpdate = (item) => {
        setShowUpdateModal(true);
        setListUpdateUser(item);
    }
    const handleClickView = (item) => {
        setShowViewModal(true);
        setListUpdateUser(item);
    }
    const handleClickBrowser = (item) => {
        setShowBrowserModal(true);
        setListUpdateUser(item);
    }
    const fetchLink = async (id) => {
        let res = await generateLink(id);
        if (res && res.statusCode === 200) {
            token.current = res.data.token;
            copyInfor(`https://birthday.viphaui.com/update-face?q=${token.current}`);
        } else {
            toast.error(res.message);
        }
    }
    const handleSearch = () => {
        if (checkAll) {
            setPagePos(1)
            fetchAllUserWithPaginate(1, search)
        } else if (checked) {
            setPagePos(1)
            fetchAllUserCheckWithPaginate(1, true, search)
        } else if (uncheck) {
            setPagePos(1)
            fetchAllUserCheckWithPaginate(1, false, search)
        }
    }
    const handleFilter = (type) => {
        if (type === 'check') {
            setPagePos(1);
            fetchAllUserCheckWithPaginate(1, true, search);
            setChecked(true);
            setCheckAll(false);
            setUnCheck(false);
        } else if (type === 'uncheck') {
            setPagePos(1);
            fetchAllUserCheckWithPaginate(1, false, search);
            setUnCheck(true);
            setChecked(false);
            setCheckAll(false);
        } else {
            setPagePos(1);
            fetchAllUserWithPaginate(1, search);
            setCheckAll(true);
            setUnCheck(false);
            setChecked(false);
        }
    }
    const reload = () => {
        setCheckAll(true);
        setChecked(false);
        setUnCheck(false);
        setSearch("");
        setPagePos(1);
        fetchAllUserWithPaginate(1, '');
    }
    const handleKeyDown = (event) => {
        if (event && event.key === 'Enter') {
            handleSearch();
        }
    }
    const exportFile = async () => {
        let res = await exportExcel();
        const url = URL.createObjectURL(res);
        const a = document.createElement('a');
        a.style = 'display: none';
        a.href = url;
        a.download = 'Danh-sach-dang-ky.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
    return (
        <div className='manage-user-container'>
            <div className='title'>
                Danh sách thành viên
            </div>
            <div className="table-action">
                <div className="table-reload" onClick={() => reload()}><AiOutlineReload /></div>
                <div className="table-search">
                    <input type="text" className="form-control" onKeyDown={(event) => handleKeyDown(event)} value={search} onChange={(event) => setSearch(event.target.value)} placeholder='Tìm kiếm' />
                    <div className="btn-search" onClick={() => handleSearch()}>Tìm kiếm</div>
                </div>
                {sort === "createdAt:desc" ? <div className="table-sort" onClick={() => setSort("createdAt:asc")}><AiOutlineSortAscending /></div> : <div className="table-sort" onClick={() => setSort("createdAt:desc")}><AiOutlineSortDescending /></div>}
                <div className="table-export" onClick={() => exportFile()}>
                    <div className="icon"><BiExport /></div>
                    <div>Excel</div>
                </div>
            </div>
            <div className='users-content'>
                <div className="btn-add-new">
                    <button className="btn btn-primary" onClick={() => setShowCreateModal(true)}><FcPlus />Thêm mới</button>
                    <div className="table-filter">
                        <div className="form-check">
                            <input checked={checkAll} value={search} onChange={() => handleFilter('all')} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Tất cả
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={() => handleFilter('check')} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Đã check-in
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={() => handleFilter('uncheck')} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                Chưa check-in
                            </label>
                        </div>
                    </div>
                </div>
                <div className="table-users-container">
                    <TableUser search={search} fetchLink={fetchLink} fetchAllUserCheckWithPaginate={fetchAllUserCheckWithPaginate} checkAll={checkAll} uncheck={uncheck} checked={checked} LIMIT_USER={LIMIT_USER} fetchAllUserWithPaginate={fetchAllUserWithPaginate} listUser={listUser} handleClickUpdate={handleClickUpdate} handleClickView={handleClickView} handleClickBrowser={handleClickBrowser} pageCount={pageCount} pagePos={pagePos} setPagePos={setPagePos} />
                </div>
                <ModalCreateUser setCheckAll={setCheckAll} setChecked={setChecked} setUnCheck={setUnCheck} pagePos={pagePos} fetchAllUserWithPaginate={fetchAllUserWithPaginate} show={showCreateModal} setShow={setShowCreateModal} setPagePos={setPagePos} />
                <ModalUpdateUser checkAll={checkAll} uncheck={uncheck} checked={checked} fetchAllUserCheckWithPaginate={fetchAllUserCheckWithPaginate} pagePos={pagePos} fetchAllUserWithPaginate={fetchAllUserWithPaginate} show={showUpdateModal} setShow={setShowUpdateModal} listUpdateUser={listUpdateUser} setListUpdateUser={setListUpdateUser} />
                <ModalViewUser show={showViewModal} setShow={setShowViewModal} listUpdateUser={listUpdateUser} setListUpdateUser={setListUpdateUser} />
                <BrowserImage checkAll={checkAll} uncheck={uncheck} checked={checked} pagePos={pagePos} fetchAllUserCheckWithPaginate={fetchAllUserCheckWithPaginate} fetchAllUserWithPaginate={fetchAllUserWithPaginate} show={showBrowserModal} setShow={setShowBrowserModal} listUpdateUser={listUpdateUser} setListUpdateUser={setListUpdateUser} />
            </div>
        </div>
    )
}

export default ManageUser