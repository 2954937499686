import React from 'react'
import unidecode from 'unidecode';
import { toast } from 'react-toastify';
import { FaRegCopy } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const InforTransfer = ({ fullName, generation, handlePrev, handleNext }) => {
    const copyInfor = async (value) => {
        const copyText = value;
        try {
            await navigator.clipboard.writeText(copyText)
            toast.success('Copy thành công!')
        }
        catch (error) {
            toast.error('Copy thất bại!')
        }
    }
    return (
        <div className='signup'>
            <div className='birthday-layout'>
                <div className='n-grid'>
                    <div style={{ gridColumn: 'span 1/ span 1' }}>
                        <div className='layout-left'>
                            <div className='left-body'>
                                <NavLink to='/'><img src='logo-vip.png' alt='logo' /></NavLink>
                                <h1>Phương thức chuyển khoản</h1>
                                <p>Bạn vui lòng chuyển khoản để hoàn tất đăng ký nhé!</p>
                            </div>
                            <img className='left-birthday' src='birthday.png' alt='birthday' />
                            <img className='left-cloud' src='cloud.png' alt='cloud' />
                        </div>
                    </div>
                    <div style={{ gridColumn: 'span 1/ span 1' }}>
                        <div className='layout-right'>
                            <div className='right-title'>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <div className='border-gradient'>
                                        <img src={`https://api.vietqr.io/image/970422-0856147600-kaEHJFl.jpg?accountName=DINH%20THI%20HIEN&amount=250000&addInfo=${unidecode(fullName).toLocaleUpperCase()} - K${generation} - dong tien sinh nhat`} alt='transfer' />
                                    </div>
                                </div>
                            </div>
                            <div className='right-body'>
                                <div className='transer-information'>
                                    <p>Chủ tài khoản: DINH THI HIEN</p>
                                    <p>Ngân hàng: MB BANK</p>
                                    <div className='transer-coppy'>
                                        <p>STK: 0856147600</p>
                                        <p className='coppy' onClick={() => copyInfor('0856147600')}><FaRegCopy /></p>
                                    </div>
                                    <p>Với kinh phí đóng về CLB là 250.000đ mỗi người hãy quét mã QR sau với nội dung: </p>
                                    <div className='transer-coppy'>
                                        <p>{`${unidecode(fullName).toLocaleUpperCase()} - K${generation} - dong tien sinh nhat `}</p>
                                        <p className='coppy' onClick={() => copyInfor(`${unidecode(fullName).toLocaleUpperCase()} - K${generation} - dong tien sinh nhat`)}><FaRegCopy /></p>
                                    </div>
                                </div>
                                <div className='button-action'>
                                    <button style={{ backgroundColor: '#f0f0f0' }} onClick={handlePrev}>Quay lại</button>
                                    <button style={{ backgroundColor: '#0d6efd', color: '#fff' }} onClick={handleNext}>Tiếp tục</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InforTransfer
