import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { postRegister } from '../../../services/apiService';
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink } from 'react-router-dom';
export const InforUser = ({ data, handlePrev, handleNext, setIdUser }) => {
    const [verfied, setVerfied] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setVerfied(true);
    };
    const handlePostRegister = async () => {
        let res = await postRegister(data.fullName, data.email, data.generation, data.phoneNumber, data.image, data.facebook, recaptchaToken);
        console.log(res)
        if (res && res.statusCode === 200) {
            toast.success('Đăng ký thành công')
            setIdUser(res?.data?.id);
            handleNext();
        } else {
            if (res.statusCode === 409 && res.message === 'Unique constraint failed on the constraint: `users_email_key`') {
                toast.error('Email bị trùng. Vui lòng nhập email khác!');
            } else if (res.statusCode === 409 && res.message === 'Unique constraint failed on the constraint: `users_facebook_key`') {
                toast.error('Facebook bị trùng. Vui lòng nhập facebook khác!');
            } else if (res.statusCode === 409 && res.message === 'Unique constraint failed on the constraint: `users_phoneNumber_key`') {
                toast.error('SĐT bị trùng. Vui lòng nhập SĐT khác!');
            } else {
                toast.error(res.message);
            }
            return;
        }
    }

    return (
        <div className='signup'>
            <div className='birthday-layout'>
                <div className='n-grid'>
                    <div style={{ gridColumn: 'span 1/ span 1' }}>
                        <div className='layout-left'>
                            <div className='left-body'>
                                <NavLink to='/'><img src='logo-vip.png' alt='logo' /></NavLink>
                                <h1>Hãy chia sẻ thông tin cá nhân để đăng ký</h1>
                                <p>Cảm ơn sự quan tâm của bạn tới bữa tiệc sinh nhật của CLB Tiếng Anh V.I.P. Xin hãy cung cấp thông tin cá nhân để hoàn tất việc đăng ký.</p>
                            </div>
                            <img className='left-birthday' src='birthday.png' alt='birthday' />
                            <img className='left-cloud' src='cloud.png' alt='cloud' />
                        </div>
                    </div>
                    <div style={{ gridColumn: 'span 1/ span 1' }}>
                        <div className='layout-right'>
                            <div className='right-title'>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <div className='border-gradient'>
                                        <img src={data.previewImage ? data.previewImage : 'cat.png'} alt='user' />
                                    </div>
                                </div>
                            </div>
                            <div className='right-body'>
                                <div className='infor-detail'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-user"><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path></svg>
                                    <span className='name-value'>{data.fullName}</span>
                                </div>
                                <div className='infor-detail'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-mail"><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path><path d="M3 7l9 6l9 -6"></path></svg>
                                    <span className='name-value'>{data.email}</span>
                                </div>
                                <div className='infor-detail'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-briefcase"><path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path><path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"></path><path d="M12 12l0 .01"></path><path d="M3 13a20 20 0 0 0 18 0"></path></svg>
                                    <span className='name-value'>{`Khóa ${data.generation}`}</span>
                                </div>
                                <div className='infor-detail'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-phone"><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path></svg>
                                    <span className='name-value'>{data.phoneNumber}</span>
                                </div>
                                <div className='infor-detail'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-brand-facebook"><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path></svg>
                                    <span className='name-value'>{data.facebook}</span>
                                </div>
                                <div style={{ marginBottom: '30px' }} className='check-robot'>
                                    <ReCAPTCHA
                                        sitekey="6Lfjk2YnAAAAANFWLKO1MjHnKlvxJqShsnkRvF0R"
                                        onChange={handleRecaptchaChange}
                                    />
                                </div>
                                <div className='button-action'>
                                    <button style={{ backgroundColor: '#f0f0f0' }} onClick={handlePrev}>Quay lại</button>
                                    <button disabled={!verfied} style={{ backgroundColor: '#0d6efd', color: '#fff' }} onClick={handlePostRegister}>Tiếp tục</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

