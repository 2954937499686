import React from 'react'
import QRCode from "qrcode.react";
import { NavLink, useNavigate } from 'react-router-dom';

const InforQR = ({ idUser, handlePrev }) => {
    const navigate = useNavigate();
    const downloadQRCode = () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${idUser}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    return (
        <div className='signup'>
            <div className='birthday-layout'>
                <div className='n-grid'>
                    <div style={{ gridColumn: 'span 1/ span 1' }}>
                        <div className='layout-left'>
                            <div className='left-body'>
                                <NavLink to='/'><img src='logo-vip.png' alt='logo' /></NavLink>
                                <h1>Mã QR Check-in</h1>
                                <p>Bạn đã đăng ký thành công</p>
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-brand-google-maps"><path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0"></path><path d="M6.428 12.494l7.314 -9.252"></path><path d="M10.002 7.935l-2.937 -2.545"></path><path d="M17.693 6.593l-8.336 9.979"></path><path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z"></path></svg>
                                    <span> Nhà hàng vườn quả, 86C Đ. Văn Tiến Dũng, Minh Khai, Bắc Từ Liêm, Hà Nội </span>
                                </p>
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" className="tabler-icon tabler-icon-clock-hour-3"><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M12 12h3.5"></path><path d="M12 7v5"></path></svg>
                                    <span> Thứ bảy, ngày 30, tháng 09, năm 2023 </span>
                                </p>
                            </div>
                            <img className='left-birthday' src='birthday.png' alt='birthday' />
                            <img className='left-cloud' src='cloud.png' alt='cloud' />
                        </div>
                    </div>
                    <div style={{ gridColumn: 'span 1/ span 1' }}>
                        <div className='layout-right'>
                            <div className='right-title'>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <div className='border-gradient'>
                                        <QRCode
                                            id="qr-gen"
                                            value={idUser}
                                            size={220}
                                            level={"H"}
                                            includeMargin={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='right-body'>
                                <div className='qr-information'>
                                    <p>Đây là mã QR của bạn</p>
                                    <p>Bạn vui lòng lưu lại để check-in tại buổi sinh nhật của V.I.P</p>
                                    <p className='text-note'>Lưu ý: Website hoạt động tốt nhất trên trình duyệt chrome, edge cho chức năng tải xuống ảnh QR.</p>
                                </div>
                                <div className='button-action'>
                                    <button style={{ backgroundColor: '#f0f0f0' }} onClick={handlePrev}>Quay lại</button>
                                    <button style={{ backgroundColor: '#fff', color: '#0d6efd', border: '1px solid #0d6efd' }} onClick={downloadQRCode}>Tải xuống</button>
                                    <button style={{ backgroundColor: '#0d6efd', color: '#fff' }} onClick={() => navigate('/')}>Trang chủ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InforQR