import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useEffect, useState } from "react";
import { AiOutlineReload, AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { getUserWithPaginate } from '../../../services/apiService';
import ModalViewUser from "./ModalViewUser";
import TableUserCheck from "./TableUserCheck";

const ManageChecked = () => {
    const LIMIT_USER = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pagePos, setPagePos] = useState(1);
    const [showViewModal, setShowViewModal] = useState(false);
    const [listUpdateUser, setListUpdateUser] = useState({});
    const [listUser, setListUser] = useState([]);
    const [initalList, setInitalList] = useState([]);
    const [search, setSearch] = useState("");
    const isCheckin = true;
    const token = useSelector(state => state.user.account.access_token);
    //filter action
    const [notify, setNotify] = useState('');
    const [notifyErr, setNotifyErr] = useState('');
    const [sort, setSort] = useState("updatedAt:desc");
    // const [searchName, setSearchName] = useState("");
    const fetchCheckedUserWithPaginate = async (page, search) => {
        let res = await getUserWithPaginate(page, LIMIT_USER, sort, isCheckin, search);
        if (res.statusCode === 200) {
            setPageCount(res.data.count);
            setInitalList(res.data.users);
            setListUser(res.data.users);
        }
    }
    const fetchData = async () => {
        await fetchEventSource("https://api.viphaui.com/api/v1/users/sse", {
            method: "GET",
            headers: {
                'Accept': "text/event-stream",
                'Authorization': 'Bearer ' + token
            },
            onopen() {
                setNotify('Kết nối dữ liệu thời gian thực thành công');
            },
            onmessage() {
                setPagePos(1);
                fetchCheckedUserWithPaginate(1, search);
            },
            onclose() {
                setNotifyErr('Kết nối bị đóng bởi server');
            },
            onerror(err) {
                setNotifyErr('Có lỗi xảy ra từ server');
            },
        });
    };
    useEffect(() => {
        fetchData();
        document.title = "Checkedin - V.I.P English Club";
    }, [])

    useEffect(() => {
        setPagePos(1);
        fetchCheckedUserWithPaginate(1, search);
    }, [sort])

    const handleClickView = (item) => {
        setShowViewModal(true);
        setListUpdateUser(item);
    }
    const handleSearch = () => {
        setPagePos(1)
        fetchCheckedUserWithPaginate(1, search)
    }

    const reload = () => {
        setSearch("");
        setPagePos(1);
        fetchCheckedUserWithPaginate(1, "");
    }

    const handleKeyDown = (event) => {
        if (event && event.key === 'Enter') {
            handleSearch();
        }
    }
    return (
        <div className='manage-user-container'>
            <div className='title'>
                Danh sách đã check-in
            </div>
            <div className="state-check">
                {notify ? <><b>Trạng thái: </b><span style={{ color: 'green', fontSize: '14px', fontWeight: '500' }}>{notify}</span></> :
                    <><b>Trạng thái: </b><span style={{ color: 'red', fontSize: '14px', fontWeight: '500' }}>{notifyErr}</span></>
                }

            </div>
            <div style={{ marginBottom: '20px' }} className="table-action">
                <div className="table-reload" onClick={() => reload()}><AiOutlineReload /></div>
                <div className="table-search">
                    <input type="text" className="form-control" value={search} onKeyDown={(event) => handleKeyDown(event)} onChange={(event) => setSearch(event.target.value)} placeholder='Tìm kiếm' />
                    <div className="btn-search" onClick={() => handleSearch()}>Tìm kiếm</div>
                </div>
                {sort === "updatedAt:desc" ? <div className="table-sort" onClick={() => setSort("updatedAt:asc")}><AiOutlineSortAscending /></div> : <div className="table-sort" onClick={() => setSort("updatedAt:desc")}><AiOutlineSortDescending /></div>}
            </div>
            <div className='users-content'>
                <div className="table-users-container">
                    <TableUserCheck search={search} pagePos={pagePos} pageCount={pageCount} LIMIT_USER={LIMIT_USER} fetchCheckedUserWithPaginate={fetchCheckedUserWithPaginate} listUser={listUser} handleClickView={handleClickView} setPagePos={setPagePos} />
                </div>
                <ModalViewUser show={showViewModal} setShow={setShowViewModal} listUpdateUser={listUpdateUser} setListUpdateUser={setListUpdateUser} />
            </div>
        </div>
    )
}

export default ManageChecked