import _ from 'lodash';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { browserImageUser } from '../../../services/apiService';
const BrowserImage = ({ checkAll, uncheck, checked, show, pagePos, fetchAllUserCheckWithPaginate, fetchAllUserWithPaginate, setShow, listUpdateUser, setListUpdateUser }) => {
    const [facebook, setFacebook] = useState("");
    const [image, setImage] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (!_.isEmpty(listUpdateUser)) {
            setImage(listUpdateUser.image);
            setFacebook(listUpdateUser.facebook)
            if (listUpdateUser.image) {
                setPreviewImage(`https://api.viphaui.com/${listUpdateUser.image}`);
            }
        }
    }, [listUpdateUser])
    const handleSubmitCheckImage = async () => {
        //call apis
        let data = await browserImageUser(listUpdateUser.id);
        if (data && data.statusCode === 200) {
            toast.success('Duyệt ảnh thành công');
            if (checkAll) {
                fetchAllUserWithPaginate(pagePos, search)
            } else if (checked) {
                fetchAllUserCheckWithPaginate(pagePos, true, search)
            } else if (uncheck) {
                fetchAllUserCheckWithPaginate(pagePos, false, search)
            }
            handleClose();
        } else {
            toast.error(data.message);
        }
    }
    const handleClose = () => {
        setPreviewImage("");
        setFacebook("");
        setImage("");
        setShow(false);
        setListUpdateUser("");
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl" backdrop="static" className="model-add-user">
                <Modal.Header closeButton>
                    <Modal.Title>Duyệt Ảnh</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginBottom: '20px' }} className="col-md-12 d-flex align-items-center gap-2">
                        <label style={{ fontSize: '20px' }}>Facebook:</label>
                        <div>
                            <a style={{ fontSize: '20px' }} href={facebook} target='_blank'>Nhấp vào đây</a>
                        </div>
                    </div>
                    <div className='col-md-12 img-preview' style={{ height: "450px" }}>
                        {previewImage ?
                            <img src={previewImage} />
                            :
                            <span>Preview Image</span>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={handleSubmitCheckImage}>
                        Duyệt
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BrowserImage;