import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const navigate = useNavigate();
    const handleLogout = () => {
        window.localStorage.clear();
        toast.success('Đăng xuất thành công');
        navigate('/login')
    }
    return (
        <>
            <Navbar expand="lg" className="header-container bg-body-tertiary" >
                <Container>
                    <NavLink to='/' className='navbar-brand'>V.I.P CLUB</NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink to='admin' className='nav-link'>Danh sách</NavLink>
                            <NavLink to='admin/checkedin' className='nav-link'>Danh sách đã check-in</NavLink>
                            <NavLink to='admin/checkin-qr' className='nav-link'>Check-in QR</NavLink>
                            <NavLink to='admin/checkin-face' className='nav-link'>Check-in Face</NavLink>
                            <NavLink to='admin/checkin-phone' className='nav-link'>Check-in SĐT</NavLink>
                        </Nav>
                        <Nav>
                            {isAuthenticated &&
                                <>
                                    <button className='btn-login btn btn-primary' onClick={handleLogout}>Đăng xuất</button>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;