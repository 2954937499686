import React, { useState, useEffect } from 'react'
import { FcPlus } from 'react-icons/fc';
import { InforUser } from './Register/InforUser';
import { toast } from 'react-toastify';
import InforTransfer from './Register/InforTransfer';
import InforQR from './Register/InforQR';
import { NavLink } from 'react-router-dom';

const SignUp = () => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [generation, setGeneration] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [image, setImage] = useState("");
    const [facebook, setFacebook] = useState("");
    const [isUpload, setIsUpload] = useState(false);
    const [isqrcode, setIsqrcode] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [next, setNext] = useState(1);
    const [idUser, setIdUser] = useState("");
    const data = {
        fullName,
        email,
        generation,
        phoneNumber,
        image,
        previewImage,
        facebook,
    }
    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const pe =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const fe = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/;

    const handleUploadImage = (event) => {
        if (event.target && event.target.files && event.target.files[0]) {
            setPreviewImage(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        }
    }
    useEffect(() => {
        document.title = "Signup - V.I.P English Club";
    }, [])
    const handleNext = () => {
        if (next === 1) {
            if (!fullName || !email || !generation || !phoneNumber || !facebook) {
                toast.error('Bạn thiếu thông tin rồi :((');
                return;
            }
            if (!re.test(email)) {
                toast.error('Email không chính xác!');
                return;
            }
            if (!pe.test(phoneNumber)) {
                toast.error('SĐT không chính xác!');
                return;
            }
            if (!fe.test(facebook)) {
                toast.error('Link facebook không chính xác!');
                return;
            }
            if (isUpload && image === '') {
                toast.error('Bạn chưa tải ảnh lên!');
                return;
            }
            if (!isUpload && !isqrcode) {
                toast.error('Bạn cần tích vào lựa chọn check-in!');
                return;
            }
        }
        setNext(next + 1);
    }
    const handlePrev = () => {
        setNext(next - 1);
    }
    return (
        <div className='register-container'>
            {next === 1 &&
                <div className='signup'>
                    <div className='birthday-layout'>
                        <div className='n-grid'>
                            <div style={{ gridColumn: 'span 1/ span 1' }}>
                                <div className='layout-left'>
                                    <div className='left-body'>
                                        <NavLink to='/'><img src='logo-vip.png' alt='logo' /></NavLink>
                                        <h1>Hãy chia sẻ thông tin cá nhân để đăng ký</h1>
                                        <p>Cảm ơn sự quan tâm của bạn tới bữa tiệc sinh nhật của CLB Tiếng Anh V.I.P. Xin hãy cung cấp thông tin cá nhân để hoàn tất việc đăng ký.</p>
                                    </div>
                                    <img className='left-birthday' src='birthday.png' alt='birthday' />
                                    <img className='left-cloud' src='cloud.png' alt='cloud' />
                                </div>
                            </div>
                            <div style={{ gridColumn: 'span 1/ span 1' }}>
                                <div className='layout-right'>
                                    <div className='right-title'>
                                        <h1>Chúc mừng V.I.P sinh nhật 12 tuổi</h1>
                                        <p>Cùng đăng ký để tham dự bữa tiệc sinh nhật ngay thôi</p>
                                        <form>
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="fullnameid" className="form-label mb-0">Họ và tên</label><span className="n-form-item-label__asterisk" style={{ color: 'red' }}>&nbsp;*</span>
                                                <input id="fullname" type="text" className="form-control" value={fullName} onChange={(event) => setFullName(event.target.value)} placeholder='Đặng Đức Duy' aria-describedby="nameHelp" />
                                            </div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="generationid" className="form-label mb-0">Sinh viên khóa</label><span className="n-form-item-label__asterisk" style={{ color: 'red' }}>&nbsp;*</span>
                                                <select required id="generationid" name='generationname' className="form-select" onChange={(event) => setGeneration(Number(event.target.value.split(" ")[1]))}>
                                                    <option value="" disabled selected>Chọn khóa</option>
                                                    <option>Khóa 01</option>
                                                    <option>Khóa 02</option>
                                                    <option>Khóa 03</option>
                                                    <option>Khóa 04</option>
                                                    <option>Khóa 05</option>
                                                    <option>Khóa 06</option>
                                                    <option>Khóa 07</option>
                                                    <option>Khóa 08</option>
                                                    <option>Khóa 09</option>
                                                    <option>Khóa 10</option>
                                                    <option>Khóa 11</option>
                                                    <option>Khóa 12</option>
                                                    <option>Khóa 13</option>
                                                    <option>Khóa 14</option>
                                                    <option>Khóa 15</option>
                                                    <option>Khóa 16</option>
                                                    <option>Khóa 17</option>
                                                </select>
                                            </div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="emailid" className="form-label mb-0">Email</label><span className="n-form-item-label__asterisk" style={{ color: 'red' }}>&nbsp;*</span>
                                                <input id="emailid" type="email" className="form-control" value={email} onChange={(event) => setEmail(event.target.value)} placeholder='dangduy02@gmail.com' aria-describedby="emailHelp" />
                                            </div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="phoneid" className="form-label mb-0">Số điện thoại</label><span className="n-form-item-label__asterisk" style={{ color: 'red' }}>&nbsp;*</span>
                                                <input id="phoneid" type="text" className="form-control" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} placeholder='0339832545' aria-describedby="phoneHelp" />
                                            </div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="facebookid" className="form-label mb-0">Link facebook</label><span className="n-form-item-label__asterisk" style={{ color: 'red' }}>&nbsp;*</span>
                                                <input id="facebookid" type="text" className="form-control" value={facebook} onChange={(event) => setFacebook(event.target.value)} placeholder='https://www.facebook.com/profile.php?id=100069769567023' aria-describedby="facebookHelp" />
                                            </div>
                                            <div className='choose-checkin'>
                                                <div style={{ margin: '10px' }}>
                                                    <input checked={isUpload} type="checkbox" id="face" onChange={() => setIsUpload(!isUpload)}></input>
                                                    <label htmlFor="face" style={{ marginLeft: '5px', color: '#000000a1', fontWeight: '600' }}>Check-in Face</label>
                                                </div>
                                                <div style={{ margin: '10px' }}>
                                                    <input checked={isqrcode} type="checkbox" id="qrcode" onChange={() => setIsqrcode(!isqrcode)}></input>
                                                    <label htmlFor="qrcode" style={{ marginLeft: '5px', color: '#000000a1', fontWeight: '600' }}>Check-in QR</label>
                                                </div>
                                            </div>
                                            {!isUpload && !isqrcode && <span className='warning-text'>Lưu ý: Vui lòng chọn phương thức Check-in!</span>}
                                            {isUpload &&
                                                <div>
                                                    <div>
                                                        <label htmlFor='labelUpload' className="form-label label-upload" data-toggle="tooltip" data-placement="top" title="Đây là một tùy chọn bổ sung. Cho phép bạn checkin bằng khuôn mặt khi tham gia buổi sinh nhật V.I.P"><FcPlus />Tải ảnh của bạn</label>
                                                        <input id="labelUpload" accept="image/*" type='file' hidden onChange={(event) => handleUploadImage(event)} />
                                                    </div>
                                                    <span className='warning-text'>Lưu ý: Vui lòng tải ảnh có khuôn mặt rõ nét!</span>
                                                    <div className='img-preview' style={{ height: "150px" }}>
                                                        {previewImage ?
                                                            <img src={previewImage} />
                                                            :
                                                            <span>Preview Image</span>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </form>
                                        <button className='btn btn-primary' style={{ margin: '20px auto', display: 'block', width: '100%', fontWeight: '600', fontSize: '18px' }} onClick={handleNext}>Tiếp tục</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {next === 2 &&
                <InforUser data={data} handlePrev={handlePrev} handleNext={handleNext} setIdUser={setIdUser} />
            }
            {
                next === 3 &&
                <InforTransfer fullName={fullName} generation={generation} handleNext={handleNext} handlePrev={handlePrev} />
            }
            {
                next === 4 &&
                <InforQR idUser={idUser} handlePrev={handlePrev} />
            }
        </div>
    )
}

export default SignUp