import React from 'react'
import { useEffect } from 'react';
import { useState } from "react";
import { useZxing } from "react-zxing";
import { checkinQR } from '../../../services/apiService';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import ModalCheckin from './ModalCheckin';

const CheckQR = () => {
    const [result, setResult] = useState("");
    const [modal, setModal] = useState("hide");
    const [count, setCount] = useState(5);
    const [nameUser, setNameUser] = useState("");
    const [generationUser, setGenerationUser] = useState("");
    const streaming = useRef(false);
    const { ref } = useZxing({
        onDecodeResult(result) {
            setResult(result.getText());
        },
    });
    const incrementCount = () => {
        setCount(count - 1);
    };
    useEffect(() => {
        const timer = setTimeout(incrementCount, 1000); // Tăng giá trị đếm sau mỗi 1000ms (1 giây)
        if (count === 0) {
            setModal("hide");
            clearTimeout(timer);
        }
        return () => {
            // Hủy bỏ đếm khi component unmount
            clearTimeout(timer);
        };
    }, [count]);
    const updateCheckinQR = async (id) => {
        if (id.length > 0) {
            let res = await checkinQR(id);
            if (res && res.statusCode === 200) {
                setNameUser(res.data.fullName);
                setGenerationUser(res.data.generation);
                setModal('open')
                setCount(5);
            }
        }
    }
    useEffect(() => {
        document.title = "Check-inQR - V.I.P English Club";
    }, [])

    useEffect(() => {
        updateCheckinQR(result);
        console.log(result);
    }, [result])

    return (
        <div className='check-qr-container'>
            <div className='title'>QRCODE CHECK-IN</div>
            <div id="outer">
                <video className="video" ref={ref} />
            </div>
            <ModalCheckin generationUser={generationUser} setModal={setModal} nameUser={nameUser} modal={modal} count={count} />
        </div>
    );
}

export default CheckQR