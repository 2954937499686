import React, { useEffect } from 'react'


const ModalCheckin = ({ setModal, modal, count, nameUser, generationUser }) => {
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                setModal('hide')
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    return (
        <div style={{ animation: modal === 'open' ? 'fadeIn 0.3s ease-in-out' : 'fadeOut 0.3s ease-in-out' }} className={`modal-checkin ${modal}`}>
            <div className="open-modal-btn">
                <div className='modal-infor'>
                    <img src='../congratulation.gif' alt='logo' />
                    <h1>Check-in thành công</h1>
                    <p>Cảm ơn anh/chị <span>{`${nameUser} - Khóa ${generationUser}`}</span> đã góp mặt trong sự kiện V.I.P 12<sup>th</sup> Birthday Party</p>
                    <div className='coutdown' onClick={() => setModal('hide')}>{`Đóng (sau ${count}s)`}</div>

                </div>
            </div>
        </div>
    )
}

export default ModalCheckin