import 'animate.css';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaRegCopy } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

const HomePage = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [showReqr, setShowReqr] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "300px" }
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  const copyInfor = async (value) => {
    const copyText = value;
    try {
      await navigator.clipboard.writeText(copyText)
      toast.success('Copy thành công!')
    }
    catch (error) {
      toast.error('Copy thất bại')
    }
  }
  useEffect(() => {
    document.title = "Home - V.I.P English Club";
  }, [])

  useEffect(() => {
    if (isIntersecting) {
      ref.current.querySelectorAll(".hidden").forEach((el) => {
        el.classList.add("show");
      });
    } else {
      ref.current.querySelectorAll(".hidden").forEach((el) => {
        el.classList.remove("show");
      });
    }
  }, [isIntersecting]);
  return (
    <>
      <div className="pyro">
        <div className="before"></div>
        <div className="after"></div>
      </div>
      <div className="wrapper">
        <header className="header">
          <div className="header-container">
            <div className="header-top">
              <img srcSet="/logo-vip.png" alt="" className="header-logo" />
            </div>
            <div className="header-main">
              <div className="header-content">
                <h1 className="header-heading animate__animated animate__backInUp animate__delay-1s">
                  V.I.P{' '}
                  <span>
                    12<sup>th</sup> Birthday Party
                  </span>
                </h1>
                <p className="header-desc animate__animated animate__fadeIn animate__delay-2s">
                  Tuổi 12 với biết bao thăng trầm và cảm xúc. Đây là một dịp đặc
                  biệt để mọi thành viên trong đại gia đình V.I.P cùng nhau ôn
                  lại những kỷ niệm đáng nhớ. Đồng thời, cùng chào đón tuổi mới
                  với nhiều thành công mới!
                </p>
                <div className="header-actions animate__animated animate__tada animate__delay-3s animate__infinite">
                  <Link to="/register" className="btn btn--primary">
                    Tham gia ngay
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main ref={ref}>
          <section className="introduce hidden">
            <div className="introduce-header">
              <h2 className="global-heading animate__animated animate__heartBeat animate__infinite animate__slower">
                <span>Lời chúc từ</span> Ban Chủ nhiệm
              </h2>
            </div>
            <div className="introduce-list">
              <div className="introduce-item">
                <div className="introduce-icon">
                  <img src="/duy.jpg" alt="tung"></img>
                </div>
                <h3 className="introduce-title">Đặng Đức Duy - Chủ nhiệm</h3>
                <p className="introduce-desc">
                  Tuổi 12 với biết bao thăng trầm và cảm xúc. Đây là một dịp đặc
                  biệt để mọi thành viên trong đại gia đình V.I.P cùng nhau ôn
                  lại những kỷ niệm đáng nhớ. Đồng thời, cùng chào đón tuổi mới
                  với nhiều thành công mới!
                </p>
              </div>
              <div className="introduce-item">
                <div className="introduce-icon">
                  <img src="/tung.jpg" alt="tung"></img>
                </div>
                <h3 className="introduce-title">
                  Tào Thanh Tùng - Phó chủ nhiệm
                </h3>
                <p className="introduce-desc">
                  Chúc V.I.P tròn 12 tuổi ngày càng vững mạnh và phát triển. Hy
                  vọng Câu Lạc Bộ sẽ mang lại được nguồn cảm hứng học tập và sự
                  say mê môn Tiếng Anh tới nhiều bạn trẻ hơn nữa!
                </p>
              </div>
              <div className="introduce-item">
                <div className="introduce-icon">
                  <img src="/ly.jpg" alt="tung"></img>
                </div>
                <h3 className="introduce-title">
                  Nguyễn Thị Lý - Phó chủ nhiệm
                </h3>
                <p className="introduce-desc">
                  Chúc mừng sinh nhật V.I.P - mái nhà thứ 2 của mình bước sang
                  tuổi 12. Hi vọng rằng, bước sang tuổi mới, V.I.P sẽ có thêm
                  thật nhiều thành tích mới, đồng thời là cầu nối đem lại những
                  cơ hội trải nghiệm, phát triển bản thân cho không chỉ cho các
                  thành viên trong CLB mà còn là cái nôi ươm mầm những thế hệ
                  mai sau đầy bản lĩnh, tự tin, năng động trong thời gian tới.
                </p>
              </div>
              <div className="introduce-item">
                <div className="introduce-icon">
                  <img src="/theanh.jpg" alt="tung"></img>
                </div>
                <h3 className="introduce-title">Vũ Thế Anh - Phó chủ nhiệm</h3>
                <p className="introduce-desc">
                  Chúc mừng Câu Lạc Bộ Tiếng Anh V.I.P bước sang tuổi 12! Chúc
                  cho Câu Lạc Bộ luôn lan toả niềm cảm hứng học tập Tiếng Anh
                  đến mọi người và đặc biệt ngày càng phát triển và đạt được
                  nhiều thành tựu mới trong sứ mệnh của mình.
                </p>
              </div>
            </div>
          </section>
          <section className="feature hidden">
            <div className="feature-header">
              <h2 className="global-heading animate__animated animate__heartBeat animate__infinite animate__slower">
                Các mốc thời gian sự kiện{' '}
                <span>
                  “V.I.P 12<sup>th</sup> Birthday Party”
                </span>
              </h2>
            </div>
            <div className="feature-main">
              <div className="feature-section">
                <div className="feature-image">
                  <img src="/sn12.jpg" alt="" />
                </div>
                <div className="feature-content">
                  <div className="timeline">
                    <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                      <div
                        className="timeline__event__icon "
                        style={{ padding: '0 72px' }}
                      >
                        <i className="lni lni-camera"></i>
                        <div className="timeline__event__date">17h30</div>
                      </div>
                      <div className="timeline__event__content ">
                        <div className="timeline__event__title">Đón khách</div>
                        <div className="timeline__event__description">
                          <p>
                            Bạn sẽ check-in tại quầy với mã QR hoặc quét nhận
                            diện gương mặt
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                      <div
                        className="timeline__event__icon"
                        style={{ padding: '0 71px' }}
                      >
                        <i className="lni lni-burger"></i>
                        <div className="timeline__event__date">18h00</div>
                      </div>
                      <div className="timeline__event__content">
                        <div className="timeline__event__title">Làm lễ</div>
                        <div className="timeline__event__description">
                          <p>
                            Những tiết mục lôi cuốn và độc đáo đang chờ đón bạn
                            như: Hát, múa, kịch, độc tấu nhạc cụ và lễ thổi nến
                            bánh kem.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                      <div className="timeline__event__icon">
                        <i className="lni lni-cake"></i>
                        <div className="timeline__event__date">19h30</div>
                      </div>
                      <div className="timeline__event__content">
                        <div className="timeline__event__title">Tiệc tối</div>
                        <div className="timeline__event__description">
                          <p>Khai tiệc!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      {showReqr && (
        <div className="overlay" onClick={() => setShowReqr(!showReqr)}></div>
      )}
      <div className="sidebar">
        <div className={showReqr ? `sidebarMenuShow` : `sidebarMenu`}>
          <div className="sidebarMenuInner">
            <div>
              <span>
                <img srcSet="/logo-vip.png" alt="" className="header-logo" />
              </span>
            </div>
            <Link to="/renew-qr" className="btn-qr">
              Cấp lại mã QR
            </Link>
            <div className="btn-qr" onClick={handleShow}>
              Mã QR chuyển khoản
            </div>
          </div>
          <label
            onClick={() => setShowReqr(!showReqr)}
            className="sidebarIconToggle"
          >
            Cấp lại QR
          </label>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="transfer-content">
            <div className="transfer-qr">
              <img src="tk.jpg" alt="transfer" />
            </div>
            <div className="transfer-infor">
              <p>Chủ tài khoản: Đinh Thị Hiền</p>
              <p>Ngân hàng: MB Bank</p>
              <div className="transer-coppy">
                <p>STK: 0856147600</p>
                <p className="coppy" onClick={() => copyInfor('0856147600')}>
                  <FaRegCopy />
                </p>
              </div>
              <p>
                Với kinh phí đóng về CLB là 250.000đ mỗi người, hãy quét mã QR
                sau với nội dung:
              </p>
              <div className="transer-coppy">
                <p>Họ tên + Khóa + "đóng tiền sinh nhật"</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default HomePage