import React from 'react';
import { NavLink } from 'react-router-dom';

const EndEvent = () => {
    return (
        <div className='register-container'>
            <div className='signup'>
                <div className='birthday-layout'>
                    <div className='n-grid'>
                        <div style={{ gridColumn: 'span 1/ span 1' }}>
                            <div className='layout-left'>
                                <div className='left-body'>
                                    <NavLink to='/'><img src='logo-vip.png' alt='logo' /></NavLink>
                                    <h1>Thông báo</h1>
                                    <p>Thời gian đăng ký sinh nhật V.I.P 12 tuổi trên hệ thống đã kết thúc.</p>
                                </div>
                                <img className='left-birthday' src='birthday.png' alt='birthday' />
                                <img className='left-cloud' src='cloud.png' alt='cloud' />
                            </div>
                        </div>
                        <div style={{ gridColumn: 'span 1/ span 1' }}>
                            <div className='layout-right'>
                                <div className='right-title'>
                                    <h1>Chúc mừng V.I.P sinh nhật 12 tuổi</h1>
                                    <p style={{ color: '#f47b2a' }}>Thời gian đăng ký đã hết. Mọi thắc mắc vui lòng liên hệ với ban chủ nhiệm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EndEvent