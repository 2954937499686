import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QRCode from "qrcode.react";

const ModalRenew = ({ show, setShow, idUser }) => {
    const downloadQRCode = () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${idUser}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Mã QR được cấp lại</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-reqr'>
                        <div className='content-reqr'>
                            <div className='qr-image'>
                                <QRCode
                                    id="qr-gen"
                                    value={idUser}
                                    size={220}
                                    level={"H"}
                                    includeMargin={true}
                                />
                            </div>
                            <div className='qr-infor'>
                                <p className='qr-head'>Hãy lưu mã QR này nhé !!</p>
                                <div className='btn-action'>
                                    <div className='btn btn-primary' onClick={downloadQRCode}>Tải mã QR</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalRenew;