import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FcPlus } from "react-icons/fc";
import { toast } from 'react-toastify';
import { updateUser } from '../../../services/apiService';
import _ from 'lodash';
const ModalUpdateUser = ({ checkAll, uncheck, checked, fetchAllUserCheckWithPaginate, show, setShow, fetchAllUserWithPaginate, listUpdateUser, setListUpdateUser, pagePos }) => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [generation, setGeneration] = useState("01");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [image, setImage] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [search, setSearch] = useState("");
    const [isCheckin, setIsCheckin] = useState(false);

    const handleUploadImage = (event) => {
        if (event.target && event.target.files && event.target.files[0]) {
            setPreviewImage(URL.createObjectURL(event.target.files[0]))
            setImage(event.target.files[0])
        }
    }
    const handleSubmitUpdateUser = async () => {
        //call apis
        console.log(typeof isCheckin)
        let data = await updateUser(listUpdateUser.id, email, phoneNumber, fullName, generation, image, isCheckin);
        if (data && data.statusCode === 200) {
            toast.success('Cập nhật người dùng thành công');
            if (checkAll) {
                fetchAllUserWithPaginate(pagePos, search)
            } else if (checked) {
                fetchAllUserCheckWithPaginate(pagePos, true, search)
            } else if (uncheck) {
                fetchAllUserCheckWithPaginate(pagePos, false, search)
            }
            handleClose();
        } else {
            toast.error(data.message);
        }
    }
    useEffect(() => {
        if (!_.isEmpty(listUpdateUser)) {
            setEmail(listUpdateUser.email);
            setFullName(listUpdateUser.fullName);
            setPhoneNumber(listUpdateUser.phoneNumber);
            setGeneration(listUpdateUser.generation);
            setImage(listUpdateUser.image);
            setIsCheckin(listUpdateUser.isCheckin);
            if (listUpdateUser.image) {
                setPreviewImage(`https://api.viphaui.com/${listUpdateUser.image}`);
            }
        }
    }, [listUpdateUser])
    const handleClose = () => {
        setEmail("");
        setFullName("");
        setPhoneNumber("");
        setPreviewImage("");
        setImage("");
        setGeneration("");
        setIsCheckin(false);
        setShow(false);
        setListUpdateUser({
            id: 0,
            fullName: "",
            phoneNumber: "",
            generation: "",
            image: "",
            email: ""
        });
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl" backdrop="static" className="model-add-user">
                <Modal.Header closeButton>
                    <Modal.Title>Cập nhật người dùng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3">
                        <div className="col-md-6">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" value={email || ''}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Số điện thoại</label>
                            <input type="text" className="form-control" value={phoneNumber || ''}
                                onChange={(event) => setPhoneNumber(event.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Họ tên</label>
                            <input type="text" className="form-control" value={fullName || ''}
                                onChange={(event) => setFullName(event.target.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Check-in</label>
                            <select className="form-select" onChange={(event) => setIsCheckin(event.target.value)} value={isCheckin}>
                                <option value={false}>Chưa check-in</option>
                                <option value={true}>Đã check-in</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Khóa</label>
                            <select className="form-select" onChange={(event) => setGeneration(Number(event.target.value.split(" ")[1]))}>
                                <option>Khóa 01</option>
                                <option>Khóa 02</option>
                                <option>Khóa 03</option>
                                <option>Khóa 04</option>
                                <option>Khóa 05</option>
                                <option>Khóa 06</option>
                                <option>Khóa 07</option>
                                <option>Khóa 08</option>
                                <option>Khóa 09</option>
                                <option>Khóa 10</option>
                                <option>Khóa 11</option>
                                <option>Khóa 12</option>
                                <option>Khóa 13</option>
                                <option>Khóa 14</option>
                                <option>Khóa 15</option>
                                <option>Khóa 16</option>
                                <option>Khóa 17</option>
                            </select>
                        </div>
                        <div className='col-md-12'>
                            <label htmlFor='labelUpload' className="form-label label-upload"><FcPlus />Tải ảnh của bạn</label>
                            <input id="labelUpload" type='file' hidden onChange={(event) => handleUploadImage(event)} />
                        </div>
                        <div className='col-md-12 img-preview' style={{ height: "150px" }}>
                            {previewImage ?
                                <img src={previewImage} />
                                :
                                <span>Preview Image</span>
                            }
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmitUpdateUser()}>
                        Cập nhật
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalUpdateUser;